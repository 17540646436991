import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';
import './styles.scss'
 
const WwuKnowMore: React.FC = (props: any) => {
  const blogCards = (): React.ReactNode => {
    const { edges } = props.props;
    return(
      edges.map((item: any) => {
        return (
          <div key={item.node.title} className="uwu-card-blog col-lg-4 col-md-4 col-sm-12">
            <div className="card-img-top">
              <img className="img-card" src={item.node.mainImage.localFile.publicURL} alt="" />
            </div>
            <div className="card-body">
              <h5 className="uwu-title-card">{item.node.title}</h5>
              <p className="text-dark-gray-2">{item.node.body.childMarkdownRemark.excerpt}</p>
              <LocalizedLink to={item.node.mainImage.localFile.publicURL}>
                <FormattedMessage id="ebook.see_more"/>
              </LocalizedLink>
            </div>
          </div>
        )
      })
    )
  }
    return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-6">
            <h2 className='text-center mt-6'><FormattedMessage id="UWU.know" /></h2>
          </div>
        </div>
        <div className="row uwu-cards-container mt-6">
          {blogCards()}
        </div>
      </div>
    </div>
  )
}

export default WwuKnowMore