import React from 'react'
import { FormattedMessage } from 'react-intl';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';
import { defaultFormatValues } from '../../../data/defaultFormat';
import backgroundJoin from './backgroundJoin.svg';
import peopleJoin from './assets/images/peopleJoin.svg';
import './styles.scss';

const WwuJoin = () => {
  return (
    <div
    style={{
      backgroundImage: `url(${backgroundJoin})`,
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      height: '600px',
      marginTop: '4rem',
      width: '100%',
    }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 join-container">
            <h2 className='text-primary'><FormattedMessage id="UWU.join.title" /></h2>
            <p className='text-primary uwu-join-desc mt-7'><FormattedMessage id="UWU.join.desc" /></p>
            <p className='text-primary uwu-join-desc mt-4'><FormattedMessage id="UWU.join.desc2" /></p>
            <LocalizedLink to='/work-with-us-jobs'>
              <button className='btn btn-primary mt-5'><FormattedMessage id="UWU.join.button" /></button>
            </LocalizedLink>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 join-container-img">
            <img src={peopleJoin} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WwuJoin