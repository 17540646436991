import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';
import wellness from './assets/images/bienestar.png';
import logo from './assets/images/logo.svg';
import people from './assets/images/people.png';
import mobile from './mobile.png';
import ArrowLink from '../../ArrowLink/ArrowLink';
import './styles.scss';

const WwuWllness = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 uwu-wellness">
            <h3 className="text-center"><FormattedMessage id="UWU.wellness.title" /></h3>
            <p className="text-center mt-6"><FormattedMessage id="UWU.wellness.desc" values={defaultFormatValues}/></p>
            <img src={wellness} alt="" className='uwu-wellness-image'/>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 uwu-why">
            <div className="uwu-why-title">
              <h2 className='text-primary'><FormattedMessage id="UWU.wellness.why.title" /></h2>
            </div>
            <div className="line"></div>
            <div className="uwu-why-desc mt-6">
              <p><FormattedMessage id="UWU.wellness.why.desc" /></p>
            </div>
          </div>
          <div className="uwu-wellness-list col-lg-6 col-md-12 col-sm-12">
            <div>
              <div className="uwu-list-item">
                <img className="uwu-list-item-img" src={logo} alt="" /><p><FormattedMessage id="UWU.wellness.why.list.one"/></p>
              </div>
              <div className="uwu-list-item">
                <img className="uwu-list-item-img" src={logo} alt="" /><p><FormattedMessage id="UWU.wellness.why.list.two"/></p>
              </div>
              <div className="uwu-list-item">
                <img className="uwu-list-item-img" src={logo} alt="" />
                <ArrowLink
                  to={"https://www.linkedin.com/company/kushki/life/b1a74f8e-c0fe-4c8b-9b2d-735f3e24e1c0/"}
                  color="primary"
                  external={true}
                  openInNewTab={true}
                  rel="noreferrer"
                >
                <FormattedMessage id="UWU.wellness.why.list.link"/>
              </ArrowLink>
              </div>
            </div>
            <div>
              <div className="uwu-list-item">
                <img className="uwu-list-item-img" src={logo} alt="" /><p><FormattedMessage id="UWU.wellness.why.list.tree"/></p>
              </div>
              <div className="uwu-list-item">
                <img className="uwu-list-item-img" src={logo} alt="" /><p><FormattedMessage id="UWU.wellness.why.list.four"/></p>          
              </div>
            </div>
          </div>
          <div className='col-lg-12 uwu-wellness-grid'>
            <img className='uwu-wellness-grid' src={people} alt="" />
            <img className='uwu-wellness-grid-res' src={mobile} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WwuWllness