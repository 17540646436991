import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';
import './styles.scss'
import idea from './assets/images/idea.svg';
import chip from './assets/images/chip.svg';
import home from './assets/images/home.svg';
import money from './assets/images/money.svg'
import subir from './assets/images/subir.svg'
import mensaje from './assets/images/mensaje.svg'
import science from './assets/images/SiftScience.svg'


const WwuConnect = () => {
  return (
    <div className="uwu-connect">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="text-center text-light"><FormattedMessage id="UWU.connect.title" /></h3>
          </div>
          <div className="col-lg-6">
            <div className="card uwu-card">
              <img src={money} alt="" className='uwu-card-image'/>
              {/* <img src={idea} alt="" className='uwu-card-image'/> */}
              <b className='text-center mt-6'><FormattedMessage id="UWU.connect.cards.card1.title"/></b>
              <p className='text-center'><FormattedMessage id="UWU.connect.cards.card1.desc"/></p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card uwu-card">
              <img src={subir} alt="" className='uwu-card-image'/>
              {/* <img src={chip} alt="" className="uwu-card-image"/> */}
              <b className='text-center mt-6'><FormattedMessage id="UWU.connect.cards.card2.title"/></b>
              <p className='text-center'><FormattedMessage id="UWU.connect.cards.card2.desc"/></p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card uwu-card">
              <img src={mensaje} alt="" className='uwu-card-image'/>
              {/* <img src={home} alt="" className="uwu-card-image"/> */}
              <b className="text-center mt-6"><FormattedMessage id="UWU.connect.cards.card3.title"/></b>
              <p className="text-center"><FormattedMessage id="UWU.connect.cards.card3.desc"/></p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card uwu-card">
              <img src={science} alt="" className="uwu-card-image"/>
              <b className="text-center mt-6"><FormattedMessage id="UWU.connect.cards.card4.title"/></b>
              <p className="text-center"><FormattedMessage id="UWU.connect.cards.card4.desc"/></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WwuConnect