import React from 'react';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';
import uwuImg from './assets/images/uwuHero.png';
import uwuHeroImg from './assets/images/uwuImg.png';
import './styles.scss';

const WwuHero = () => {
  return (
    <div className="uwu-container">
      <div className="uwu-image-res"
        style={{
          height: '880px',
          width: '100%',
        }}
      >
        <img src={uwuHeroImg} alt=""className="uwu-img-res"/>
        <div className="container hero">
          <div className="row uwu-desc">
            <div className="col-lg-4 col-md-4 col-sm-12 p-4">
              <h1 className="text-light"><FormattedMessage id="UWU.title" /></h1>
              <p className="text-light mt-4"><FormattedMessage id="UWU.desc" /></p>
              <p className="text-light mt-4"><FormattedMessage id="UWU.desc2" /></p>
              <LocalizedLink to="/work-with-us-jobs">
                <button className="btn btn-light m-4">
                  <FormattedMessage id="UWU.button" />
                </button>
              </LocalizedLink>
            </div>
          </div>
        </div>
      </div>
      <div className="uwu-image"
        style={{
          backgroundImage: `url(${uwuImg})`,
          backgroundPosition: '50% 50%',
          backgroundSize: 'cover',
          height: '700px',
          width: '100%',
        }}
      >
        <div className="container hero">
          <div className="row uwu-desc">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <h1 className="text-light"><FormattedMessage id="UWU.title" /></h1>
              <p className="text-light mt-4"><FormattedMessage id="UWU.desc" /></p>
              <p className="text-light mt-4"><FormattedMessage id="UWU.desc2" /></p>
              <LocalizedLink to="/work-with-us-jobs">
                <button className="btn btn-light mt-4">
                  <FormattedMessage id="UWU.button" />
                </button>
              </LocalizedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WwuHero