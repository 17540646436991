import React from 'react'
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';
import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import right from './assets/images/arrowRight.svg';
import left from './assets/images/arrowLeft.svg';
import lina from './assets/images/lina.png';
import adri from './assets/images/adri.png';
import carla from './assets/images/carla.png';
import './styles.scss';

const WwuCarrousel = () => {
  const contentCarousel = () => {
    if (typeof window !== 'undefined') {
      return (
      <Carousel 
        slides={[
          ( 
          <div className="card uwu-card-carrousel mt-6">
            <div className="col-md-3">
              <img src={lina} className="uwu-card__carrousel-image" alt="Lina" />
            </div>
            <div className="col-md-9">
              <div className='uwu-card-content'>
                <h2 className='text-white'>Lina Rodríguez</h2>
                <p className='text-white'>Diseñadora Junior</p>
                <p className='text-white'>"Como estudiante, Kushki patrocinó mis prácticas para finalizar mis estudios. Siempre sentí el apoyo en el proceso de ingresar al mundo laboral, viviendo experiencias únicas. Luego, mi líder me comentó que querían que continuara como Diseñadora Juinior.</p>
                <p className='text-white'>Estoy disfrutando trabajar en Kushki al 100%."</p>
              </div>
            </div>
          </div>
          ),
          (
          <div className="card uwu-card-carrousel">
            <div className="uwu-card-content row">
              <div className="col-md-3 col-12">
                <img src={carla} className=" uwu-card__carrousel-image adri-img" alt="Carla" />
              </div>
              <div className='col-md-9 col-12 pt-xs-3'>
                <h2 className='text-white'>Carla Santoliva</h2>
                <p className='text-white'>Key Account Manager</p>
                <p className='text-white'>“Tenía 3 meses en Kushki cuando me enteré de mi embarazo. Desde que lo anuncié en la Nación, me sentí totalmente respaldada. Cuando volví, tuve todo el apoyo y pude disfrutar a mi bebé gracias al trabajo remoto.</p>
                <p className='text-white'>Agradecida de ser una mamá Kushkeña.”</p>
              </div>
            </div>
          </div>
          ),
          (
          <div className="card uwu-card-carrousel">
            <div className='uwu-card-content row'>
              <div className="col-md-3 col-12">
                <img src={adri} className="uwu-card__carrousel-image adri-img" alt="Adri" />
              </div>
              <div className="col-md-9 col-12 pt-xs-3">
                <h2 className='text-white'>Adriana Acuña</h2>
                <p className='text-white'>Gerente de Employee Experience</p>
                <p className='text-white'>“Hace 10 meses convertirme en Gerente de Employee Experience era un sueño y hoy se ha vuelto uno de los proyectos más retadores de mi vida. </p>
                <p className='text-white'>Nunca es fácil para una mujer entrar a una empresa de tecnología, pero sin duda todo el equipo me ha ayudado a aprender lo necesario para seguir aportando a esta gran Nación.”</p>
              </div>
            </div>
          </div>
            )
          ]}
          plugins={[
            'infinite',
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 1
              }
            },
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: <img src={left} alt="left" className='carrousel-arrows'/>,
                arrowRight: <img src={right} alt="right" className='carrousel-arrows'/>,
                addArrowClickHandler: true,
                draggable: true
              }
            }
        ]}/>
        )
      }
    }
  return (
    <div className='uwu-carrousel'>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <h2 className='text-center text-light'><FormattedMessage id="UWU.carrousel.title" /></h2>
            </div>
            <div>
              {  contentCarousel() }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WwuCarrousel