import React from 'react'
import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import Footer from '../components/Footer/Footer'
import { LocaleTypes } from '../data/locales'
import IndexLayout from '../layouts'
import SEO from '../components/SEO/SEO';
import WwuHero from '../components/wwu/WwuHero/WwuHero'
import WwuConnect from '../components/wwu/WwuConnect/WwuConnect';
import WwuWllness from '../components/wwu/WwuWellness/WwuWllness';
import WwuCarrousel from '../components/wwu/WwuCarrousel/WwuCarrousel';
import WwuKnowMore from '../components/wwu/WwuKnowMore/WwuKnowMore';
import EbookContent from '../components/Ebook/EbookContent/EbookContent';
import WwuJoin from '../components/wwu/WwuJoin/WwuJoin';


interface WWUProps {
  pathContext: {
    locale: LocaleTypes;
  };
  location: Location;
}


const wwu: React.FC<WWUProps> = ({ data, pathContext, location }) => {
  const content = (): React.ReactNode => {
    const { allContentfulBlogPost } = data;
    return (
      <>
        <SEO title="work with us" />
        <WwuHero />
        <WwuConnect />
        <WwuWllness />
        <WwuCarrousel />
        <WwuKnowMore props={allContentfulBlogPost}/>
        <WwuJoin />
        <Footer theme='primary' />
      </>
    )
  }
  return <IndexLayout render={ content } locale={pathContext.localeCode} navbarTheme='default' location={location} />
}

export default wwu

export const query = graphql`
  {
    allContentfulBlogPost(limit: 3) {
      edges {
        node {
          title
          slug
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          mainImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
